import React from 'react'

class FieldLabel extends React.Component{

	render() {
		let field = this.props.field

		return (

			<label className="form__label" htmlFor={field.slug}>
				{field.label}
				{field.required &&
					<span className="form__label-required">*</span>
				}
				{field.desc &&
					<span className="form__desc">{field.desc}</span>
				}
			</label>

		)
	}
}

export default FieldLabel