export default {
	fields: [
		[
			{
				label: "First Name",
				slug: "first_name",
				type: "text",
				value: "",
				required: true,
				error: {
					value: true,
					message: "This field cannot be empty"
				},
				updated: false
			},
			{
				label: "Last Name",
				slug: "last_name",
				type: "text",
				value: "",
				required: true,
				error: {
					value: true,
					message: "This field cannot be empty"
				},
				updated: false
			},
			{
				label: "Business Name",
				slug: "business_name",
				type: "text",
				value: "",
				required: false,
				error: { value: false },
				updated: false
			},
			{
				label: "Email",
				slug: "email",
				type: "email",
				value: "",
				required: true,
				error: {
					value: true,
					message: "This field needs to be an email address"
				},
				updated: false
			},
			{
				label: "Phone",
				slug: "phone",
				type: "tel",
				value: "",
				required: true,
				error: {
					value: true,
					message: "This field cannot be empty"
				},
				updated: false
			},
			{
				label: "Contact Preference",
				slug: "contact",
				type: "radio",
				value: "contact_email",
				required: false,
				error: { value: false },
				options: [
					{
						label: "Email",
						slug: "contact_email",
					},
					{
						label: "Phone",
						slug: "contact_phone",
					}
				],
				updated: false
			},
			{
				label: "How did you hear about us?",
				slug: "how_hear_select",
				type: "select",
				value: "",
				options: [
					{
						label: "Online Search",
						slug: "how_online",
					},
					{
						label: "Repeat User",
						slug: "how_repeat",
					},
					{
						label: "Venue Referral",
						slug: "how_repeat",
					},
					{
						label: "Word of Mouth",
						slug: "how_mouth",
					},
				],
				required: true,
				error: {
					value: true,
					message: "Please choose an option"
				},
				updated: false
			},
			{
				label: "Give a shout out to the person or venue who referred you!",
				slug: "how_hear",
				type: "text",
				value: "",
				required: false,
				error: {
					value: false
				},
				updated: false
			}
		],
		[
			{
				label: "Venue Type",
				type: "checkbox",
				options: [
					{
						label: "Unique Venue",
						value: "venue_type_unique",
						checked: false,
					},
					{
						label: "Restaurant / Private Dining Space",
						value: "venue_type_dining",
						checked: false,
					},
					{
						label: "Hotel",
						value: "venue_type_hotel",
						checked: false,
					}
				],
				required: true,
				error: {
					value: false,
					message: "Please choose an option"
				},
				updated: true,
			},
			{
				label: "Event Type",
				slug: "event_type",
				type: "select",
				value: "",
				options: [
					{
						label: "Corporate Event/Meeting",
						value: "corporate_event",
					},
					{
						label: "Holiday",
						value: "holiday"
					},
					{
						label: "Nonprofit",
						value: "non_profit"
					},
					{
						label: "Performance/Theatre-style/Ticketed",
						value: "performance"
					},
					{
						label: "Social (Birthday, Mitzvah, Celebration of Life)",
						value: "social_celebration"
					},
					{
						label: "Wedding/Rehearsal Dinner",
						value: "wedding"
					},
					{
						label: "Other",
						value: "other"
					},
				],
				required: true,
				error: {
					value: true,
					message: "Please choose an option"
				},
				updated: false
			},
			{
				label: "Other Event Type",
				slug: "event_type_other",
				type: "text",
				value: "",
				required: false,
				error: { value: false },
				updated: false
			},
			{
				label: "Estimated Number of Guests",
				desc: "Enter a single whole number, no dash please.",
				slug: "guests_total",
				type: "number",
				value: "",
				required: true,
				error: {
					value: true,
					message: "Number of Guests is required"
				},
				updated: false
			},
			{
				label: "Event Date",
				slug: "date",
				type: "date",
				value: null,
				required: true,
				error: {
					value: true,
					message: "Pick a date!"
				},
				updated: false
			},
			{
				label: "Is your event date flexible?",
				slug: "date_firm",
				type: "radio",
				value: "firm",
				required: false,
				error: { value: false },
				options: [
					{
						label: "Yes",
						slug: "flexible",
					},
					{
						label: "No",
						slug: "firm",
					}
				],
				updated: false
			},
			{
				label: "Additional Date Information",
				slug: "date_alt_info",
				type: "textarea",
				value: "",
				required: false,
				error: { value: false },
				updated: false
			},
			{
				label: "Event Start Time",
				slug: "start_time",
				type: "time",
				value: null,
				required: true,
				error: {
					value: true,
					message: "This field cannot be empty"
				},
				updated: false
			},
			{
				label: "Desired Locale",
				desc: "Select up to 3",
				slug: "locale",
				type: "multiselect",
				options: [
					{
						label: "Denver & Surrounding",
						options: [
							{
								label: "Denver Central",
								value: "locale_denver_central",
								checked: false,
							},
							{
								label: "Aurora/East Denver",
								value: "locale_denver",
								checked: false,
							},
							{
								label: "Westminster/North",
								value: "locale_metro_north",
								checked: false,
							},
							{
								label: "Boulder Corridor/Surrounding",
								value: "locale_boulder",
								checked: false
							},
							{
								label: "South Metro/Douglas County",
								value: "locale_metro_south",
								checked: false
							},
							{
								label: "Foothills/Western Metro",
								value: "locale_metro_west",
								checked: false,
							},
							{
								label: "Longmont",
								value: "locale_longmont",
								checked: false,
							},
							{
								label: "Other",
								value: "locale_other",
								checked: false,
							},
						]
					}
				],
				required: true,
				error: {
					value: true,
					message: "A Desired Locale is required"
				},
				updated: false
			},
			{
				label: "Desired Locale Other",
				slug: "locale_other_text",
				type: "text",
				value: "",
				required: false,
				error: { value: false },
				updated: false
			},
			{
				label: "Beverage",
				type: "checkbox",
				options: [
					{
						label: "Hosted Bar",
						value: "hosted",
						checked: false
					},
					{
						label: "Non-alcoholic Offerings",
						value: "non_alc",
						checked: false
					},
					{
						label: "Cash Bar",
						value: "cash_bar",
						checked: false
					},
					{
						label: "Non-profit Donated Product",
						value: "non_profit",
						checked: false
					}
				],
				required: false,
				error: { value: false },
				updated: false
			},
			{
				label: "Catering",
				slug: "catering",
				type: "textarea",
				value: "",
				required: false,
				error: { value: false },
				updated: false
			},
			{
				label: "Audio & Visual Equipment Needs",
				slug: "audio_visual",
				type: "textarea",
				value: "",
				required: false,
				error: { value: false },
				updated: false
			},
			{
				label: "Overall Event Budget",
				desc: "Why this, you ask? Budget isn’t a bad word – venues have unique offerings, their rates vary by season & day of the week, some have AV or catering onsite, others have linens – so let them help you compare apples to apples.",
				slug: "budget",
				type: "text",
				value: "",
				required: true,
				error: {
					value: true,
					message: "This field cannot be empty"
				},
				updated: false
			},
			{
				label: "Additional Budget Information",
				slug: "budgetinfo",
				type: "textarea",
				value: "",
				required: false,
				error: { value: false },
				updated: false
			},
			{
				label: "Tell us how you envision your event",
				slug: "envision",
				type: "textarea",
				value: "",
				required: false,
				error: { value: false },
				updated: false
			},
		],
	]
}