import React from 'react'

class MainCircle extends React.Component{

	render() {
		return (

			<div className="main-content">
				<div className="main-content__column">
					{this.props.children}
				</div>
				<div className="bg-circle"></div>
			</div>

		)
	}
}

export default MainCircle