import React, { Component } from 'react'

class Modal extends Component {

	render() {
		return (

			<div className={'modal' + ((this.props.active === true) ? ' is-active' : '' )}>
				<button className="modal__close" onClick={this.props.toggleModal}>Close</button>
				<div className="modal__content">
					{this.props.children}
				</div>
			</div>

		)
	}
}

export default Modal