import React, { Component } from 'react'

class FormCheckbox extends Component {

	handleChange = (v, e) => {
		this.props.handleChange(this.props.index, this.props.field, e);
		this.props.toggleArrayItem(v);
	}

	render() {
		let { field } = this.props

		return (

			<div className="form__checkboxes">

				{field.options.map((option, i) => {
					let v = option.value;

					let isDisabled = false;
					if(this.props.active !== undefined) {
						isDisabled = (!this.props.active.includes(v) && (this.props.active.length >= 3)) ? true : false;
					}

					return (

						<div className="form__checkbox" key={i}>
							<input
								disabled={(isDisabled) ? "disabled" : ""}
								type="checkbox"
								id={v}
								name={v}
								checked={option.checked}
								onChange={(e) => { this.handleChange(v, e); }}
							/>
							<label htmlFor={option.value}>{option.label}</label>
						</div>

					)
				})}
			</div>

		)
	}
}

export default FormCheckbox