import React from 'react'

class NavIcon extends React.Component {

	render() {
		return (

			<button className="header__nav-toggle" onClick={this.props.handleNavToggle}>
				<span className="header__nav-toggle-text">Menu</span>
				<span className="header__nav-toggle-icon"></span>
			</button>

		)
	}
}

export default NavIcon