import React from 'react'

import iconEmail from '../img/svg/icon-email.svg'
import iconFacebook from '../img/svg/icon-facebook.svg'
import iconInstagram from '../img/svg/icon-instagram.svg'
import iconTwitter from '../img/svg/icon-twitter.svg'

class FieldListSuccess extends React.Component {



	render() {
		let isActive = (this.props.active === 2) ? ' is-active' : ''

		return (

			<div className={"field-list" + isActive}>
				<div className="success">
					<h2 className="success__title">Success!</h2>
					<p>Thank you for submitting your event details to VenuHub. Your information has been shared with our network of venues who will reach out to you directly if their space is available.</p>
					<p><a href="https://venuhub.com/colorado-event-vendors/" target="_blank" rel="noopener noreferrer">Click Here</a> to connect with Colorado's best special event vendors.</p>
					<ul className="social-list">
						<li className="social-list__item social-list__item--email">
							<a href="mailto:hello@venuhub.com" className="social-list__link">
								<img src={iconEmail} alt="Email VenuHub" className="social-list__icon" />
							</a>
						</li>
						<li className="social-list__item social-list__item--facebook">
							<a href="https://www.facebook.com/venuhubcolorado" className="social-list__link">
								<img src={iconFacebook} alt="Facebook Icon" className="social-list__icon" />
							</a>
						</li>
						<li className="social-list__item social-list__item--email">
							<a href="https://www.instagram.com/venuhub/" className="social-list__link">
								<img src={iconInstagram} alt="Instagram Icon" className="social-list__icon" />
							</a>
						</li>
						<li className="social-list__item social-list__item--twitter">
							<a href="https://twitter.com/venuhub" className="social-list__link">
								<img src={iconTwitter} alt="Twitter Icon" className="social-list__icon" />
							</a>
						</li>
					</ul>
				</div>
			</div>

		)
	}
}

export default FieldListSuccess