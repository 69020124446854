import React from 'react'

import Header from './Header'
import Footer from './Footer'
import IEWarning from './IEWarning'
import Modal from './Modal'
import ContentPrivacyPolicy from './ContentPrivacyPolicy'

class Page extends React.Component {

	constructor(props) {
		super(props)
	
		this.state = {
			activeModal: false
		}
	}

	componentDidMount() {
		window.scrollTo(0,0)
	}

	toggleModal = (e) => {
		e.preventDefault()
		let currentState = this.state.activeModal
		this.setState({
			activeModal: !currentState
		})
		document.body.classList.toggle('modal-open')
	}

	render() {
		return (

			<div className="page" id="top">
				<IEWarning />
				<Modal toggleModal={this.toggleModal} active={this.state.activeModal}>
					<ContentPrivacyPolicy />
				</Modal>
				<Header />
				<div className="page__content">
					{this.props.children}
				</div>
				<Footer />
			</div>

		)
	}
}

export default Page