import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import FindVenue from './components/FindVenue'
import PrivacyPolicy from './components/PrivacyPolicy'
import NotFound from './NotFound'

class App extends React.Component {
	
	render() {
		return (

			<Router>
				<Switch>
					<Route path="/" exact component={FindVenue} />
					<Route path="/privacy-policy" exact component={PrivacyPolicy} />
					<Route component={NotFound} />
				</Switch>
			</Router>

		)
	}
}

export default App
