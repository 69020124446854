import React from 'react';

import Creatable from 'react-select/creatable';
import { components } from 'react-select';

import FieldLegend from './FieldLegend'

const Menu = (props) => {
	const optionSelectedLength = props.getValue().length || 0;
 	return (
		<components.Menu {...props}>
			{optionSelectedLength < 3 ? (
				props.children
			) : (
				<div style={{ margin: 15 }}>Max limit achieved</div>
			)}
		</components.Menu>
	)
}

const FormMultiSelect = (props) => {
	
	let { field } = props

	const handleChange = (e) => {
		props.handleChange(props.index, props.field, e);
		// props.toggleArrayItem(e.value)
	}

	return (

		<div className="form__block">
			<div className="form__multiselect">
				<FieldLegend field={field} />
				{field.desc &&
					<span className="form__desc">{field.desc}</span>
				}
				<Creatable
					components={{ Menu }}
					isMulti={true}
					options={props.field.options}
					onChange={handleChange}
				/>
			</div>
		</div>

	)
}

export default FormMultiSelect;