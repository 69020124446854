import React from 'react'

import FormRadio from './FormRadio'
import FormDate from './FormDate'
import FormTime from './FormTime'
import FormReceive from './FormReceive'
import FormTextarea from './FormTextarea'
import FormCheckboxList from './FormCheckboxList'
import FormCheckboxLocale from './FormCheckboxLocale'
import FormSelect from './FormSelect'
import FormInput from './FormInput'
import FormMultiSelect from './FormMultiSelect'
import LeadFormNav from './LeadFormNav'

class FieldList extends React.Component {

	render() {
		return (

			<div className={'field-list' + this.props.activeClass}>
				{this.props.fieldList.map((field, i) => {
					let type = field.type
					// Radio
					if(type === 'radio')
						return (

							<FormRadio
								field={field}
								index={i}
								key={i}
								handleChange={this.props.handleChange}
							/>

						)
					else
					// Date Field
					if(type === 'date')
						return (

							<FormDate
								field={field}
								index={i}
								key={i}
								handleChange={this.props.handleChange}
								handleBlur={this.props.handleBlur}
							/>

						)
					else
					// Time Field
					if(type === 'time')
						return (
						
							<FormTime
								field={field}
								index={i}
								key={i}
								handleChange={this.props.handleChange}
								handleBlur={this.props.handleBlur}
							/>
						
						)
					else
					// Checkbox
					if(type === 'checkbox')
						return (

							<FormCheckboxList
								field={field}
								index={i}
								key={i}
								handleChange={this.props.handleChange}
								handleError={this.props.handleError}
							/>

						)


					else
					// Checkbox Locale
					if(type === 'multiselect')
						return (

							<FormMultiSelect
								field={field}
								index={i}
								key={i}
								toggleModal={this.props.toggleModal}
								handleChange={this.props.handleChange}
								handleError={this.props.handleError}
							/>

						)

					else
					// Checkbox Locale
					if(type === 'checkbox-locale')
						return (

							<FormCheckboxLocale
								field={field}
								index={i}
								key={i}
								toggleModal={this.props.toggleModal}
								handleChange={this.props.handleChange}
								handleError={this.props.handleError}
							/>

						)
					else
					// Checkbox Receive
					if(type === 'checkbox-receive')
						return (

							<FormReceive
								field={field}
								index={i}
								key={i}
								handleChange={this.props.handleChange}
								toggleModal={this.props.toggleModal}
							/>

						)
					else
					// Textarea
					if(type === 'textarea')
						return <FormTextarea field={field} index={i} key={i} handleChange={this.props.handleChange} />
					else
					// Select
					if(type === 'select')
						return <FormSelect field={field} index={i} key={i} handleChange={this.props.handleChange} handleBlur={this.props.handleBlur} />
					// Input
					else
						return <FormInput field={field} index={i} key={i} handleChange={this.props.handleChange} handleBlur={this.props.handleBlur} />
				})}

				<LeadFormNav
					index={this.props.index}
					listsComplete={this.props.listsComplete}
					activeList={this.props.activeList}
					adjustFieldList={this.props.adjustFieldList}
					handleSubmit={this.props.handleSubmit}
					toggleModal={this.props.toggleModal}
				/>
			</div>

		)
	}
}

export default FieldList