import React from 'react'

import FormError from './FormError'
import FieldLabel from './FieldLabel'

class FormInput extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			focus: false,
			isNumber: (this.props.field.type === 'number') ? true : false,
		}
	}

	handleChange = (e) => {
		this.props.handleChange(this.props.index, this.props.field, e);
		
		// console.log('value: ' + e.target.value);
		// console.log('type: ' + this.props.field.type);

		// if(e.target.value !== '-' && this.props.field.type !== 'number') {
		// 	console.log('I was runnin');
		// 	this.props.handleChange(this.props.index, this.props.field, e);
		// }
	}

	handleFocus = (e) => {
		e.preventDefault()
		this.setState({ focus: true })
	}

	handleBlur = (e) => {
		this.setState({ focus: false })
		this.props.handleBlur(this.props.index, e)
	}

	render() {
		let { field } = this.props,
				isActive = ' is-active',
				activeClass = (this.state.focus) ? isActive : (field.value !== '') ? isActive : ''

		return (

			<div className={'form__block form__block--input' + activeClass}>
				<div className="form__input">
					<FieldLabel field={field}  />
					<input id={field.slug} value={field.value} type={field.type} required={field.required}
						min={(this.state.isNumber) ? "0" : null}
						step={(this.state.isNumber) ? "1" : null}
						onChange={this.handleChange}
						onFocus={this.handleFocus}
					/>

					{(field.error.value === true && field.updated === true) &&

						<FormError field={field} />

					}

				</div>
			</div>

		)
	}
}

export default FormInput