import React from 'react'

import FieldLabel from './FieldLabel'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

class FormDate extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			date: new Date()
		}

		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(date) {
		this.props.handleChange(this.props.index, this.props.field, date)
	}

	handleBlur = (e) => {
		this.props.handleBlur(this.props.index, e)
	}

	render() {
		let { field } = this.props

		return (

			<div className="form__block form__block--date">
				<div className="form__date">
					<FieldLabel field={field} />
					<DatePicker
						selected={field.value}
						onChange={this.handleChange}
						minDate={new Date()}
						// onBlur={this.handleBlur}
						dateFormat="MM/dd/yyyy"
					/>

				</div>
			</div>

		)
	}
}

export default FormDate