import React from 'react'

import FieldLabel from './FieldLabel'

class FormSelect extends React.Component {

	constructor(props) {
		super(props)

		this.handleChange = this.handleChange.bind(this)
	}

	handleChange = (e) => {
		this.props.handleChange(this.props.index, this.props.field, e)
	}

	handleBlur = (e) => {
		this.props.handleBlur(this.props.index, e)
	}

	render() {
		let { field } = this.props

		return (

			<div className="form__block">
				<div className="form__select">
					<FieldLabel field={field} />
					<div className="form__block-content">
						<select name={field.slug} id={field.slug} value={field.value}
							onChange={this.handleChange}
							// onBlur={this.handleBlur}
						>
							<option value="">--Choose an option--</option>
							{field.options.map((option, i) => {
								return (

									<option value={option.value} key={i}>{option.label}</option>

								)
							})}
						</select>
					</div>
				</div>
			</div>

		)
	}
}

export default FormSelect