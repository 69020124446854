import React from 'react'

class LeadFormProgress extends React.Component {

	render() {
		return (

			<ul className="form__progress">
				{this.props.fields.map((field_list, i) => {
					let activeClass = (this.props.activeList === i) ? ' is-active' : ''
					return (

						<li className={"form__progress-item" + activeClass} key={i}>{i}</li>
					
					)
				})}
				<li className="form__progress-item form__progress-item--result">{this.props.fields.length}</li>
			</ul>

		)
	}
}

export default LeadFormProgress