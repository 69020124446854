import React, { Component } from 'react'

import FieldLabel from './FieldLabel';

class FormRadio extends Component {

	handleChange = (e) => {
		this.props.handleChange(this.props.index, this.props.field, e)
	}

	render() {
		let { field } = this.props

		return (

			<div className="form__block">
				<div className="form__radios">
					<FieldLabel field={field} />

					<div className="form__block-content">
						{field.options.map((option, i) => {
							return (

								<div className="form__radio" key={i}>
									<input type="radio" id={option.slug}
										value={option.slug}
										checked={field.value === option.slug}
										onChange={this.handleChange}
									/>
									<label htmlFor={option.slug}>{option.label}</label>
								</div>

							)
						})}
					</div>
				</div>
			</div>

		)
	}
}

export default FormRadio