import React from 'react'

import { NavHashLink as NavLink } from 'react-router-hash-link'
import NavIcon from './NavIcon'

class Header extends React.Component {

	constructor(props) {
	  super(props)
	
	  this.state = {
	  	navHeight: this.setNavHeight()
	  }

	  window.addEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		this.setState({
			navHeight: this.setNavHeight()
		})
	}

	setNavHeight = () => {
		return (window.innerWidth >= 750) ? 'auto' : 0
	}

	handleNavToggle = () => {
		let navHeight = document.getElementById('nav-list').clientHeight
		this.setState({
			navHeight: (this.state.navHeight === 0) ? navHeight : 0
		})
	}

	render() {
		let navItems = [
			// {
			// 	title: "About",
			// 	slug: "",
			// 	hash: "about",
			// 	button: false,
			// },
			// {
			// 	title: "Vendor Friends",
			// 	slug: "featured-vendors",
			// 	hash: "",
			// 	button: false,
			// },
			// {
			// 	title: "Find Your Venue",
			// 	slug: "find-your-venue",
			// 	hash: "",
			// 	button: true,
			// },
		]

		return (

			<header id="header" className="header" role="banner">
				
				<a href="#about" className="button skip-link screen-reader-text">Skip to Content</a>

				<div className="header__content">
					<a href="https://venuhub.com/" className="header__link">Visit the Full VenuHub website</a>
					<NavIcon handleNavToggle={this.handleNavToggle} />
					<nav className="header__nav" style={{height: this.state.navHeight}}>
						<ul className="header__nav-list" id="nav-list">
							{navItems.map((item, key) => {
								let href = {
									pathname: (item.slug !== "") ? item.slug : "/",
									hash: item.hash,
								}
								return (

									<li className={"header__nav-item" + ((item.slug !== "") ? " header__nav-item--" + item.slug : "")} key={key}>
										<NavLink to={href} smooth exact className="header__nav-link">{item.title}</NavLink>
									</li>

								)
							})}
						</ul>
					</nav>
				</div>
			</header>

		)
	}
}

export default Header