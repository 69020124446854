import React from 'react'
import update from 'immutability-helper'
import validator from 'validator'

import FieldList from './FieldList'
import FieldListSuccess from './FieldListSuccess'
import LeadFormProgress from './LeadFormProgress'

import logoVenuhub from '../img/svg/logo-venuhub.svg'
import fields from '../data/fields'

class LeadForm extends React.Component {

	constructor() {
		super()
		this.state = {
			activeList: 0,
			activeModal: false,
			listsComplete: null,
			fields: fields.fields
		}
	}

	handleBlur = (i, val) => {
		let field = this.state.fields[this.state.activeList][i]

		if(field.required === true){
			let valid = true

			switch(field.type) {
				case 'select':
				case 'number':
				case 'text':
				case 'tel':
				case 'date':
				case 'time':
					valid = validator.isEmpty(val)
					break
				case 'email':
					valid = !validator.isEmail(val)
					break

				default:
					break
			}

			this.handleError(valid, i)
		}
	}

	handleRequired = (val) => {
		let valErr = (val.error.value || val.error.value !== null);

		return (
			!val.required || (valErr && val.required && val.updated)
		)
	}

	handleError = (val, i) => {
		let fields = update(this.state, {
			fields: {
				[this.state.activeList]: {
					[i]: {
						error: {
							value: { $set: val }
						}
					}
				}
			}
		})

		let compl,
			al = this.state.activeList,
			lc = this.state.listsComplete;

		if(val && al === lc){
			compl = (al === 0) ? null : (al - 1)
		} else {
			compl = al
		}

		this.setState(fields, () => {
			let reqCom = this.state.fields[this.state.activeList].every(this.handleRequired);

			if(reqCom) {
				this.setState({
					listsComplete: compl
				});
			}
		})
	}

	handleChange = (i, f, e) => {

		let fields, state,
			list = this.state.activeList;

		if(f.type === 'multiselect') {

			state = this.state;
			f.options.forEach((option, index) => {
				if(option.options !== undefined) {
					option.options.forEach((oo, ii) => {
						let current = (e !== null && e.length),
							currentMatch = (current) ? e.some(el => el.value === oo.value) : false;

						state = update(state, {
							fields: { [list]: { [i]: {
								options: { [index]: {
									options: { [ii]: {
										checked: { $set: currentMatch }
									}}
								}},
								error: { value: { $set: false }},
								updated: { $set: true }
							}}}
						})
					});
				} else {
					let current = (e !== null && e.length),
						currentMatch = (current) ? e.some(el => el.value === option.value) : false;

					state = update(state, {
						fields: { [list]: { [i]: {
							options: { [index]: {
								checked: { $set: currentMatch }
							}}
						}}}
					})
				}
			});

			this.setState(state);

		} else if(f.type === 'checkbox' || f.type === 'checkbox-locale') {
			let optindex = f.options.findIndex(item => item.value === e.target.id)
			fields = update(this.state, {
				fields: { [list]: { [i]: {
					options: {
						[optindex]: {
							checked: { $set: e.target.checked }
						}
					},
					error: {
						value: { $set: false }
					},
					updated: { $set: true }
				}}}
			})
			
			this.setState(fields, () => {
				this.handleError(false, i);
			});

		} else if(f.type === 'radio') {
			
			let val = e.target.value;

			fields = update(this.state, {
				fields: { [list]: { [i]: {
					value: { $set: val },
					error: {
						value: { $set: false }
					},
					updated: { $set: true }
				}}}
			});

			this.setState(fields, () => {
				this.handleError(false, i);
			});

		} else {
			let val = (f.type === 'date' || f.type === 'time') ? e : e.target.value;

			fields = update(this.state, {
				fields: { [list]: { [i]: {
					value: { $set: val },
					updated: { $set: true }
				}}}
			});

			this.setState(fields, () => {
				this.handleBlur(i, val.toString());
			});
		}
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let lead = this.buildFormObj();
		
		window
			.fetch('/api/leads', {
				method: 'POST',
				body: JSON.stringify({ lead }),
				headers: { 'Content-Type': 'application/json' }
			})
			.then(resp => resp.json())
			.then((json) => {
				let activeNew = this.state.activeList + 1
				this.setState({
					activeList: activeNew
				})
			})
			.catch(err => console.log(err))
	}

	adjustFieldList = (i, e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		this.setState({
			activeList: i
		})
	}

	buildFormObj = () => {
		let obj = {},
				fields = this.state.fields

		fields.forEach(function(f, i) {
			f.forEach(function(block, i) {
				if(block.type === 'multiselect'){
					block.options.forEach(function(option, i) {
						if(option.value !== undefined) {
							obj[option.value] = option.checked;
						} else {
							option.options.forEach(function(o, i) {
								obj[o.value] = o.checked;
							})
						}
					})
				} else
				if(block.type === 'checkbox' || block.type === 'checkbox-locale'){
					block.options.forEach(function(option, i) {
						obj[option.value] = option.checked
					})
				}	else {
					obj[block.slug] = block.value
				}
			})
		})

		return obj
	}

	render() {
		let { fields } = this.state

		return (

			<form className="form">

				<img src={logoVenuhub} alt="VenuHub" className="form__logo" />
				<div className="tac">
					<p><b>Colorado's Simplified Venue Search</b><br />Just one form. Venues find you.</p>
				</div>

				{fields.map((fieldList, i) => {
					let  activeClass = (this.state.activeList === i) ? ' is-active' : ''
					return (
					
						<FieldList
							fieldList={fieldList}
							listsComplete={this.state.listsComplete}
							activeList={this.state.activeList}
							activeClass={activeClass}
							adjustFieldList={this.adjustFieldList}
							handleChange={this.handleChange}
							handleSubmit={this.handleSubmit}
							handleBlur={this.handleBlur}
							handleError={this.handleError}
							toggleModal={this.props.toggleModal}
							index={i}
							key={i}
						/>
					
					)
				})}

				<FieldListSuccess active={this.state.activeList} />

				<LeadFormProgress fields={fields} adjustFieldList={this.adjustFieldList} activeList={this.state.activeList} />

			</form>

		)
	}
}

export default LeadForm