import React from 'react'

import FormCheckbox from './FormCheckbox'

class FormReceive extends React.Component {

	render() {
		let { field } = this.props

		return (

			<div className="form__block">
				<FormCheckbox field={field} handleChange={this.props.handleChange} />
				<div className="form__text">
					<p>Your privacy is important to us. Read our <button onClick={this.props.toggleModal}>Privacy Agreement</button> here to see how your information is collected and dispersed to our network.</p>
				</div>
			</div>

		)
	}
}

export default FormReceive