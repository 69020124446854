import React, { Component } from 'react'

class IEWarning extends Component {

	constructor() {
	  super();
	
	  this.state = {
	  	isVisible: window.navigator.userAgent.match(/(MSIE|Trident)/)
	  }
	}

	toggleWarning = () => {
		this.setState({
			isVisible: ''
		})
	}

	render() {

		return (

			<div id="ie-warning" className={"ie-warning" + ((this.state.isVisible) ? " is-visible" : "") }>
				<button className="ie-warning__toggle" onClick={this.toggleWarning}>close</button>
				<div className="ie-warning__content">
					<h1 className="ie-warning__title">Thank you for visiting VenuHub!</h1>
					<p>For the optimal VenuHub experience, please use another browser such as <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="noopener noreferrer">Edge</a>, <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Chrome</a>, <a href="https://support.apple.com/downloads/safari" target="_blank" rel="noopener noreferrer">Safari</a>, or <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">Firefox</a>.</p>
				</div>
			</div>

		)
	}
}

export default IEWarning