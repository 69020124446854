import React from 'react'

class FieldLegend extends React.Component{

	render() {
	
		return (

			<legend className="form__legend">
				{this.props.field.label}
				{this.props.field.required &&
					<span className="form__legend-required">*</span>
				}
			</legend>


		)
	}
}

export default FieldLegend