import React, { useState } from 'react'

import FormCheckbox from './FormCheckbox'
import FieldLegend from './FieldLegend'

const FormCheckboxList = (props) => {
	let { field } = props;

	const [ active, setActive ] = useState([]);

	const toggleArrayItem = (v) => {
		let a = active,
			i = a.indexOf(v);

		if(i === -1) {
			a.push(v);
		} else {
			a.splice(i, 1);
		}

		setActive(a);
	}

	return (

		<div className="form__block">
			<FieldLegend field={field} />
			<div className="form__block-content">
				<FormCheckbox
					field={field}
					handleChange={props.handleChange}
					handleError={props.handleError}
					toggleArrayItem={toggleArrayItem}
					index={props.index}
				/>
			</div>
		</div>

	)
}

export default FormCheckboxList;