import React from 'react'

class FormTextarea extends React.Component {

	constructor(props) {
		super(props)

		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(e) {
		this.props.handleChange(this.props.index, this.props.field, e)
	}

	render() {
		let { field } = this.props

		return (

			<div className="form__block">
				<div className="form__textarea">
					<label htmlFor={field.slug}>{field.label}</label>
					<div className="form__block-content">
						<textarea value={field.value} name={field.slug} id={field.slug} cols="30" rows="10" onChange={this.handleChange}></textarea>
					</div>
				</div>
			</div>

		)
	}
}

export default FormTextarea