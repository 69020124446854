import React from 'react'

import venuhubLogo from '../img/svg/logo-venuhub-blue.svg'

class ContentPrivacyPolicy extends React.Component {

	render() {
		return (

			<div className="pp-content">
				<img src={venuhubLogo} className="pp-content__logo" alt=""/>
				<h2>VenuHub, LLC Privacy Policy</h2>
				<p>This Privacy Policy describes how your Personal Information is collected, used, and shared when you utilize VenuHub.com. “Personal Information” means any information you provide to us when you fill-out and submit your venue search to us.</p>
				<h3>How do we use your personal information?</h3>
				<p>Should you opt to submit your Personal Information, we submit your event information to our screened and curated list of “Venue Members.” Only those Venue Members who meet your needs will respond. As part of our curation process, Venue Members have previously agreed to respond only one time, unless you reply to the Venue Member and initiate further contact. Additionally, we use this Venue Search Information to communicate with you. Our members have signed a proprietary information agreement prohibiting them from utilizing your information for communication or any other purpose outside of your venue search.</p>
				<h3>Sharing your personal information</h3>
				<p>We only share your Personal Information with our curated group of Venue Members.</p>
				<p>We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your <a href="https://www.google.com/intl/en/policies/privacy/" rel="noopener noreferrer" target="_blank">Personal Information</a>.  You can also opt-out of <a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">Google Analytics</a>.</p>
				<p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
				<h3>Changes</h3>
				<p>We may update this privacy policy from time to time, at our discretion, to reflect changes to our practices, or for other operational, legal or regulatory reasons.</p>
				<h3>Contact Us</h3>
				<p>For more information about our privacy practices, or if you have questions, contact us by mail or phone, using the details provided here: VenuHub, LLC P.O. Box 2472, Denver, CO, 80201 or by calling 303-892-2141.</p>
			</div>

		)
	}
}

export default ContentPrivacyPolicy