import React from 'react'

import Page from './Page'
import ContentPrivacyPolicy from './ContentPrivacyPolicy'

class PrivacyPolicy extends React.Component {


	render() {
		return (

			<Page>
				<div className="page__content">
					<ContentPrivacyPolicy />
				</div>
			</Page>

		)
	}
}

export default PrivacyPolicy