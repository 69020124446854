import React from 'react'

import ligatureLogo from '../img/svg/logo-ligature.svg'

class Footer extends React.Component {

	constructor(props) {
	  super(props)
	
	  this.state = {
	  	refs: [
	  		{
	  			name: "Open To All Colorado",
	  			url: "https://opentoallcolorado.com/",
	  			logo: "https://s3.us-east-2.amazonaws.com/venu-hub/logo-ota.png",
	  		},
	  		{
	  			name: "#OPENCO",
	  			url: "https://opentoallcolorado.com/",
	  			logo: "https://venu-hub.s3.us-east-2.amazonaws.com/logo-openco.png",
	  		}
	  	]
	  }
	}

	render() {
		let currentYear = new Date().getFullYear()
		
		return (

			<footer className="footer" role="contentinfo">
				<div className="footer__content">
					<div className="footer__copy">
						<span className="footer__copy-text">{currentYear} VenuHub, LLC &reg;</span>
					</div>
					<div className="footer__refs">
						<ul className="open-refs">
							{this.state.refs.map(function(r, i) {
								return (
									
									<li className="open-refs__item" key={i}>
										<a href={r.url} className="open-refs__link" target="_blank" rel="noopener noreferrer">
											<img src={r.logo} alt={r.name} className="open-refs__logo" />
										</a>
									</li>

								)
							})}
						</ul>
					</div>
					<div className="footer__cred">
						<span className="footer__cred-text">Design &amp; Development by</span>
						<a href="https://ligcreative.com/" className="footer__cred-link" target="_blank" rel="noopener noreferrer">
							<img src={ligatureLogo} alt="Ligature Creative Logo" className="footer__cred-logo"/>
						</a>
					</div>
				</div>
			</footer>

		)
	}
}

export default Footer