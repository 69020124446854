import React from 'react'

import FormCheckbox from './FormCheckbox'
import FieldLegend from './FieldLegend'

export default class FormCheckboxLocale extends React.Component {

	constructor(props) {
	  super(props);
	
	  this.state = {
	  	limit: 3,
	  	active: [],
	  };
	}

	toggleArrayItem = (v) => {
		let a = this.state.active,
			i = a.indexOf(v);

		if(i === -1) {
			a.push(v);
		} else {
			a.splice(i, 1);
		}

		this.setState({
			active: a
		});
	}

	render() {
		let { field } = this.props

		return (

			<div className="form__block">
				<FieldLegend field={field} />
				<div className="form__details">
					<p><button className="form__locale-toggle" onClick={this.props.toggleModal}>View Map of Locales</button></p>
				</div>
				{field.desc &&
					<span className="form__desc">{field.desc}</span>
				}
				<div className="form__block-content">
					<FormCheckbox
						field={field}
						handleChange={this.props.handleChange}
						handleError={this.props.handleError}
						index={this.props.index}
						limit={this.state.limit}
						active={this.state.active}
						toggleArrayItem={this.toggleArrayItem}
					/>
				</div>
			</div>

		)
	}
}