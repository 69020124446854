import React from 'react'

class FormError extends React.Component {

	render() {
		let field = this.props.field,
				errorClass = (field.error.value && field.updated) ? ' form__error--active' : ''

		return (

			<div className={'form__error' + errorClass}>
				<span className="form__error-icon"></span>
				<p>{field.error.message}</p>
			</div>
			
		)
	}
}

export default FormError