import React from 'react'
import { Link } from 'react-router-dom'

class LeadFormNav extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			submitActive: true,
		}

		this.handleClick = this.handleClick.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleSubmit(e) {
		let submitActive = this.state.submitActive
		this.setState({
			submitActive: !submitActive
		})

		this.props.handleSubmit(e)
	}

	handleClick(e) {
		this.props.adjustFieldList(this.props.activeList + 1, e);
	}

	render() {
		let { activeList } = this.props

		return (

			<nav className="form__nav">
				{this.props.index === 0 ? (

					<div className="tac">
						<p>Contact <a href="mailto:hello@venuhub.com">hello@venuhub.com</a> with any questions or issues.</p>
						{this.props.listsComplete !== this.props.activeList ? (
							<button className="button button--small" disabled>Please complete all required fields.</button>
						):(
							<button className="button" onClick={this.handleClick} disabled={false}>Next</button>
						)}
					</div>
				
				):(
					<div>
						<div className="p--small">
							<p>By clicking Submit, you agree to receive communications from our network of venues to assist you with this specific event inquiry. Your contact information will not be used for any other purpose.</p>
							<p>Your privacy is important to us. Read our <Link to="/privacy-policy" target="_blank">Privacy Agreement</Link> to see how your information is collected and dispersed to our network.</p>
							<p>Contact <a href="mailto:hello@venuhub.com">hello@venuhub.com</a> with any questions or issues.</p>
						</div>
						<div className="tac">
							<div className="form__back">
									<button className="asdf" onClick={(e) => this.props.adjustFieldList(activeList - 1, e)}>Back</button>
							</div>

							{this.state.submitActive === true ? (
								<React.Fragment>
									{this.props.listsComplete !== this.props.activeList ? (
										<button className="button button--small" disabled>Please complete all required fields.</button>
									):(
										<button className="button" onClick={this.handleSubmit}>Submit Form</button>
									)}

								</React.Fragment>
							):(
								<div className="form-sub">
									<span className="form-sub__icon"></span>
								</div>
							)}
						</div>
					</div>
				)}
			</nav>

		)
	}
}

export default LeadFormNav