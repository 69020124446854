import React from 'react'

import FieldLabel from './FieldLabel'

import TimePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

// import moment from 'moment'

// var moment = require('moment')
// moment.tz.setDefault("America/Denver")

// export const formatUTC = (dateInt, addOffset = false) => {
//     let date = (!dateInt || dateInt.length < 1) ? new Date : new Date(dateInt);
//     if (typeof dateInt === "string") {
//         return date;
//     } else {
//         const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
//         const offsetDate = new Date();
//         offsetDate.setTime(date.getTime() + offset * 60000)
//         return offsetDate;
//     }
// }

class FormTime extends React.Component {

	handleChange = (time) => {
		console.log(time);
		this.props.handleChange(this.props.index, this.props.field, time)
	}

	handleBlur = (e) => {
		this.props.handleBlur(this.props.index, e)
	}

	render() {
		let { field } = this.props

		return (
			
			<div className="form__block form__block--time">
				<div className="form__time">
					<FieldLabel field={field} />
					<TimePicker
						selected={field.value}
						onChange={this.handleChange}
						showTimeSelect
						showTimeSelectOnly
						dateFormat="h:mm aa"
						timeFormat="h:mm aa"
						timeCaption="Time"
					/>

				</div>
			</div>

		)
	}
}

export default FormTime