import React from 'react'

import Page from './Page'
import MainCircle from './MainCircle'
import LeadForm from './LeadForm'
import Modal from './Modal'

class FindVenue extends React.Component {

	constructor() {
		super()

	  this.state = {
	  	activeModal: false
	  }
	}

	componentDidMount = () => {
		document.addEventListener("keydown", this.handleEsc, false);
	}

	componentWillUnmount = () => {
		document.removeEventListener("keydown", this.handleEsc, false);
	}

	handleEsc = (e) => {
		if(e.key === 'Escape' && this.state.activeModal === true){
			this.toggleModal(e);
		}
	}

	toggleModal = (e) => {
		e.preventDefault()
		let activeCurrent = this.state.activeModal
		this.setState({
			activeModal: !activeCurrent
		})
	}

	render() {
		return (
			
			<Page>
				<Modal active={this.state.activeModal} toggleModal={this.toggleModal}>
					<div className="modal-map">
						<img src="https://venu-hub.s3.us-east-2.amazonaws.com/map-overlay.jpg" alt="Map of Denver Area" className="modal-map__image" />
					</div>
				</Modal>
				<MainCircle>
					<LeadForm toggleModal={this.toggleModal} />
				</MainCircle>
			</Page>

		)
	}
}

export default FindVenue